<template>
	<div :style="background" class="about">
		<my-title></my-title>
		<div class="body">
			<div class="body-left">
				<div class="item">软件名称：智慧大厅</div>
				<div class="item">软件版本：V0.1.0</div>
				<div class="item">设备类型：叫号器</div>
				<div class="item-id">
					<p>设备ID：</p>
					<input class="id-input" v-model="macAddr" />
					<div class="id-modify" @click="modifyId()">修改</div>
				</div>
				<div class="item">LED窗口地址：{{LEDAddr}}</div>
			</div>
      <div class="qrcode">
				<div style="width:180px;height:180px" ref="qrCodeUrl"></div>
			</div>
		</div>
		<div class="bottom">
			<span class="sure" @click="back">返回</span>
		</div>
		<el-dialog title="提示" :visible.sync="dialogAbout" width="50%" he>
			<div>
				<span>请输入密码：</span>
				<input class="about-input" type="password" v-model="setPassword" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialAbout">取 消</el-button>
				<el-button type="primary" @click="sureDialAbout">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import MyTitle from "../components/MyTitle.vue"
import api from "../api/index";
import { Toast } from 'vant'
import QRCode from 'qrcodejs2'
export default {
	components: {
		MyTitle
	},
	data () {
		return {
			background: {
				backgroundColor: '#5A9AD1',
				// 背景图片地址
				backgroundImage: 'url(' + require('../assets/image/lineup_comm_bg_land.png') + ')',
				// 背景图片是否重复
				backgroundRepeat: 'no-repeat',
				// 背景图片大小
				backgroundSize: '100% auto',
				// 背景图片位置
				backgroundPosition: '0 0'

			},
			macAddr: '',
			LEDAddr: '',
			resetScoket: true,
			socket: null,
			timer: null,
			setPassword: '',
			dialogAbout: false
		}
	},
	mounted () {
		this.macAddr = localStorage.getItem("macAddr");
		this.LEDAddr = sessionStorage.getItem("ledServiceAddress");
		this.initWebSocket(this.macAddr)
		this.creatQrCode()
	},
	destroyed () {
		this.resetScoket = false;
		this.socket?.close()
		clearInterval(this.timer)
		this.timer = null
	},
	methods: {
		creatQrCode () {
			var qrcode = new QRCode(this.$refs.qrCodeUrl, {
				text: this.macAddr, // 需要转换为二维码的内容
				width: 180,
				height: 180,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode.CorrectLevel.H
			})
		},
		back () {
			this.$router.go(-1)
		},
		modifyId () {
			this.setPassword = ''
			this.dialogAbout = true
			// this.$confirm('确认修改设备ID吗', '提示', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// }).then(() => {
			// 	localStorage.setItem("macAddr", this.macAddr)
			// 	this.$router.replace({
			// 		name: 'DeviceConnect',
			// 	})
			// }).catch(() => {
			// });
		},
		closeDialAbout () {
			this.dialogAbout = false
		},
		sureDialAbout () {
			if (!this.setPassword) {
				Toast('请输入密码')
				return
			}
			this.getValidatePassword()
		},
		//叫号器 - 设备密码校验
		getValidatePassword: async function () {
			const res = await api.validatePassword(this.macAddr, this.setPassword);
			if (res.code == 200) {
				this.dialogAbout = false
				localStorage.setItem("macAddr", this.macAddr)
				this.$router.replace({
					name: 'DeviceConnect',
				})
			} else {
				Toast(res.msg + '')
			}
		},


		initWebSocket (macAddr) {
			this.socket = new WebSocket("wss://xcx.etuic.com/eval-api/ws/queue/" + macAddr)
			this.socket.onopen = this.socketOnOpen
			this.socket.onmessage = this.socketOnMessage;
			this.socket.onclose = this.socketOnClose;
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
			this.timer = setInterval(() => {
				this.socket?.send(macAddr);
			}, 180000)
		},
		socketOnMessage (res) {
			console.log("后台传递数据", res);
			if (/^客户端/.test(res.data) || /^设备/.test(res.data)) {
				return
			}
			let json = JSON.parse(res?.data ?? '{}')
			let code = json.code;
			if (code === 101 || code === 110) { //平台设置设备 code:101 叫号机退出登录 code:110
				removeToken()
				this.$router.replace({
					name: 'DeviceConnect',
					query: {
						refresh: true
					}
				})

			}
		},
		socketOnClose () {
			if (this.resetScoket) {
				this.initWebSocket(this.macAddr);
			}
		},
		socketSendMessgae (mes) {
			let message = JSON.stringify(mes);
			this.socket?.send(message);
		}
	}
}
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
	padding: 15px 20px;
}
::v-deep .el-dialog__header {
	padding: 10px 20px 0;
}
.about {
	height: 100%;
  box-sizing: border-box;
	display: flex;
	position: relative;
	overflow: auto;
	flex-direction: column;
	.body {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		color: #fff;
		font-size: 14px;
		padding-top: 60px;
		box-sizing: border-box;
		padding-left: 180px;
    padding-right: 250px;
		.qrcode {
			display: flex;
      padding-bottom: 50px;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
		.item {
      font-size: 30px;
      padding-bottom: 40px;
      
		}
		.item-id {
		 font-size: 30px;
      padding-bottom: 40px;
			display: flex;
			flex-direction: row;
			.id-input {
				color: #000;
			}
			.id-modify {
				background-color: #3f76fe;
				margin-left: 10px;
				border-radius: 2px;
				padding: 2px 5px;
			}
		}
	}
	.bottom {
		display: flex;
		color: #fff;
		width: 100%;
    padding-top: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		.sure {
			background-color: #84c7ff;
			border-radius: 5px;
      font-size: 25px;
			width: 230px;
			height: 60px;
			line-height: 60px;
			text-align: center;
		}
	}
}
.about-input {
	color: #000;
	margin-top: 10px;
}
</style>
